import { PATH_FOR_YM_PARAMS } from 'utils/locationsYmParams';

/**
 * @deprecated нужно использовать "@beef/metrics"
 * YMMetricHelper({...}).pushEvent
 */

export const ymPushEvent = (key = '', payload = {}, id = 26001372) => {
  if (window.ym) {
    window.ym(id, 'reachGoal', key, payload);
  }
};

/**
 * @deprecated нужно использовать "@beef/metrics"
 * YMMetricHelper({...}).pushEvent
 */
export const ymPushWithUrl = (key = '', payload = {}) => {
  ymPushEvent(key, {
    URL: document.location.href,
    ...payload,
  });
};

/**
 * @deprecated нужно использовать "@beef/metrics"
 * YMMetricHelper({...}).sendParams
 */
export const ymPushParams = (payload = {}, id = 26001372) => {
  if (window.ym) {
    window.ym(id, 'params', payload);
  }
};

/**
 * @deprecated нужно использовать "@beef/metrics"
 * YMMetricHelper({...}).sendParams
 */
export const ymSetParams = (params) => {
  const path = window.location.pathname.replace(/\/?$/, '/');
  if (Object.values(PATH_FOR_YM_PARAMS).includes(path)) {
    ymPushParams(params);
  }
};
