import axios from 'axios';
import { softSecureRequestInterceptor } from '@beef/utils';

const secureInterceptorFilter = ({ url }) => {
  // eslint-disable-next-line sonarjs/prefer-single-boolean-return
  if (
    // если урл запроса содержит widgetpay1
    // или если урл запроса содержит vimpelcom.ru
    // или урл насчинается с https(s), или c //, или с www И НЕ включает beeline.ru
    // то Secure Request Interceptor ВЫключается
    url.includes('widgetpay1') ||
    url.includes('vimpelcom.ru') ||
    /^(https?:\/\/|www\.|\/\/)(?!.*beeline\.ru)/.test(url)
  ) {
    return false;
  }

  // Для остальных урлов ВКЛЮЧАЕМ Secure Request Interceptor
  return true;
};

if (process.env.RUN_ENV !== 'server') {
  require('smoothscroll-polyfill').polyfill();
  require('raf').polyfill();

  axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
  };

  if (window.secureInterceptor) {
    axios.interceptors.request.use(softSecureRequestInterceptor(secureInterceptorFilter), (error) =>
      Promise.reject(error),
    );
  }
}
